html,
body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #FAFFF7;
}

* {
  box-sizing: border-box;
}

:root {
  --blue: #70bf6a;
  --green: #8BE1CF;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semibold: 600;
  --bold: 700;
}

a {
  color: var(--blue);
  transition: 0.3s;
  cursor: pointer;
}

a:hover {
  opacity: 0.8;
}

img {
  max-width: 100%;
}

.container {
  margin: 0 auto;
}

.w1385 {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.w1385 {
  max-width: 1385px;
  margin: 0 auto;
}
.w1387 {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.w1387 {
  max-width: 1385px;
  margin: 0 auto;
}

.w1388 {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.w1388 {
  max-width: 1385px;
  margin: 0 auto;
}
.w1386 {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #94d3a2; /* Green background color */
 

}

.w1386 {
  max-width: 1385px;
  margin: 0 auto;
}


.anchor {
  scroll-margin-top: 70px;
}

section {
  padding-top: 50px;
}

.std-iframe {
  width: 100%;
  overflow: hidden;
  border: 0;
}

.std-iframe iframe {
  width: 100%;
  border: none;
}

.std-title {
  font-size: 30px;
  font-weight: var(--medium);
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.std-writeup {
  color: #333;
  line-height: 160%;
  font-size: 18px;
}

.std-title.white {
  color: #fff;
  border-color: #fff;
}

.std-writeup.white {
  color: #fff;
}

.std-title.no-border {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

/*Header*/
header {
  position: relative;
  /* background: #d7edf9; */
}

header.transparent-bg {
  background: transparent;
}

header.full-height {
  height: 95vh;
}

header.full-height .header-content {
  padding-bottom: 0;
  height: 100%;
  display: flex;
}

header.full-height .header-content .big-title {
  top: unset;
}

header.full-height .header-content .wrapper {
  width: 100%;
}

header .logo img {
  max-height: 125%;
}

header .video-container {
  position: relative;
  top: 52px;
  background: #94d3a2;
  height: 266px;
}
header .video-container-fellows{
  position: relative;
  top: 52px;
  background: #94d3a2;
  height: 266px;

}
header video {
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.fade-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
}

header .logo-menu {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  transition: 0.3s;
  display: none;
}

header .logo-menu.relative {
  position: relative;
}

header .logo-menu.sticky {
  position: fixed;
  background-color: #FAFFF7;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

header .logo-menu.sticky .content {
  padding-top: 20px;
  padding-bottom: 15px;
}

header .logo-menu.sticky .socials img {
  height: 15px;
}

header .logo-menu .content {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

header .logo {
  height: 67px;
}

header .socials {
  gap: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

header .socials img {
  height: 25px;
  transition: 0;
}

header .big-title {
  font-size: 25px;
  color: var(--blue);
  font-weight: var(--medium);
  text-align: center;
  position: absolute;
  z-index: 1;
  max-width: 760px;
  left: 0;
  right: 0;
  top: 38%;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(-20px);
  animation: fadeInMoveDown 1s forwards;
}

@keyframes fadeInMoveDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

header nav {
  border-top: 1px solid var(--blue);
  margin-top: 10px;
  padding-top: 0px;
  padding-left: 10px;
  display: flex;
  gap: 0 10px;
  align-items: center;
}

header nav ul {
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0 5px;
}

header nav ul li {
  list-style-type: none;
  position: relative;
  
}

header nav ul li a {
  text-decoration: none;
  padding: 10px 30px;
  display: inline-block;
}


/* Remove the arrow for links without dropdown */
header nav ul li>a:after {
  display: none; /* Hide the arrow by default */
  
}

/* Show the arrow only for links that have a dropdown */
header nav ul li.has-dropdown > a:after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}


header nav ul li {
  position: relative;
  
}

header nav ul li .dropdown {
  display: flex;
  flex-direction: column;
  max-height: 0;
  position: absolute;
  visibility: hidden;
  transition: max-height 0.5s ease, visibility 0.5s ease;
  overflow: hidden;
  width: 100%;
  border-top: 1px solid #fff;
}

header nav ul li a {
  transition: 0.5s;
  color: var(--blue);
  
}

header nav ul li a:hover,
header nav ul li:hover>a {
  background-color: var(--blue);
  color: #fff;
  opacity: 1;
}

header nav ul li .dropdown a {
  padding: 10px;
  text-align: center;
  background-color: var(--blue);
  color: #fff;
  border-bottom: 1px solid #fff;
  width: 100%;
  transition: 0.3s;
  font-size: 15px;
}

header nav ul li .dropdown a:last-of-type {
  border-bottom: 0;
}

header nav ul li .dropdown a:hover {
  background-color: var(--green);
  color: var(--blue);
  opacity: 1;
}

header nav ul li:hover .dropdown {
  max-height: 500px;
  visibility: visible;
}

header nav .open-search {
  width: 30px;
  cursor: pointer;
  text-align: right;
  transition: 0.3s;
}

header nav .open-search img {
  display: block;
  height: 22px;
}

header nav .open-search:hover {
  opacity: 0.8;
}

.header-content {
  padding-top: 120px;
  padding-bottom: 50px;
}

.header-content .big-title {
  position: relative;
  animation: none;
  opacity: 1;
  transform: unset;
}

.header-content .subtitle {
  text-align: center;
  color: var(--blue);
  font-size: 22px;
  margin-top: 10px;
  line-height: 140%;
  animation: fadeInMoveDown 1s forwards;
}

.header-content .three-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 10px;
  flex-direction: column;
  gap: 30px 0;
}

.header-content .three-cards .item {
  width: 85%;
  background-color: #FAFFF7;
  border-radius: 20px;
  padding: 15px;
  box-shadow: -10px 10px 0 0 #8be1cf, -10px 10px 0 0 #8be1cf;
  cursor: pointer;
  transition: 0.3s;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  align-items: center;
  gap: 0 20px;
}

.header-content .three-cards .item:first-of-type {}

.header-content .three-cards .item:hover {
  transform: translate(-10px, 10px);
  box-shadow: none;
  opacity: 1;
}

.header-content .three-cards .icon {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 15%;
  margin: 10px auto 0 auto;
}

.header-content .three-cards .icon img {
  max-height: 100%;
}

.header-content .three-cards .title {
  text-align: center;
  font-size: 22px;
  text-decoration: none;
  font-weight: var(--medium);
  margin-top: 10px;
}

.header-content .image {
  text-align: center;
  margin-top: 30px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Mobile Header */
.mobile-header {
  background-color: var(--blue);
  width: 100%;
  position: fixed;
  z-index: 3;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  gap: 0 20px;
}

.mobile-header .logo {
  height: 40px;
  margin-right: auto;
}

.mobile-header .logo img {
  display: block;
  max-height: 100%;
}

.mobile-header .open-search {
  height: 27px;
}

.mobile-header .open-search img {
  max-height: 100%;
}

.mobile-header .hamburger {
  width: 30px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.mobile-header .hamburger .patty {
  background-color: #fff;
  height: 3px;
  border-radius: 2px;
  transition: 0.3s;
}

.mobile-header .hamburger.active .patty:nth-of-type(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.mobile-header .hamburger.active .patty:nth-of-type(2) {
  opacity: 0;
}

.mobile-header .hamburger.active .patty:nth-of-type(3) {
  transform: rotate(-45deg) translate(8px, -8px);
}

.slidemenu {
  top: -620px;
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 2;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.slidemenu.active {
  top: 70px;
  padding: 25px 15px 15px 15px;
}

.slidemenu ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.slidemenu ul li {
  list-style-type: none;
}

.slidemenu a {
  text-decoration: none;
}

.slidemenu .title {
  margin-bottom: 13px;
  font-weight: var(--medium);
  font-size: 22px;
  display: block;
}

.slidemenu .content {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  text-align: left;
}

.slidemenu .group {
  font-size: 22px;
  padding-bottom: 15px;
  padding-left: 10px;

}

.slidemenu .group.socials {
  display: flex;
  gap: 0 10px;
  border-bottom: 0;
  justify-content: center;
}

.slidemenu .group.socials img {
  height: 25px;
}



/* Footer */
footer {
  background-color: var(--blue);
  margin-top: 50px;
  padding: 50px 0;
}

footer .row-two {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #fff;
  padding-top: 25px;
  margin-top: 15px;
  flex-wrap: wrap;
}

footer .col {
  width: 100%;
  color: #fff;
  font-size: 15px;
  border-bottom: 1px solid #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

footer .col .title {
  margin-bottom: 18px;
  font-weight: var(--bold);
  color: #fff;
  font-size: 20px;
  display: inline-block;
  text-decoration: none;
}

footer .col ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

footer .col ul li {
  list-style-type: none;
}

footer .col ul li a {
  color: #fff;
  text-decoration: none;
}

footer .col ul li a:hover {
  text-decoration: underline;
}

footer .col form .subscribe-field {
  border: 0;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
}

footer .col form .subscribe-field:focus {
  outline: none;
}

footer .col form .subscribe-submit {
  background-color: var(--green);
  border: 0;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  font-weight: var(--semibold);
  cursor: pointer;
  transition: 0.3s;
}

footer .col form .subscribe-submit:hover {
  opacity: 0.8;
}

footer .col.about-work .mt-20 {
  margin-top: 20px;
}

footer .email {
  text-decoration: underline;
}

footer .map {
  margin-bottom: 10px;
  padding-bottom: 0;
  border-bottom: 0;
  position: relative;
}

footer .map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

footer .col.subscribe {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

footer .btt {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s;
}

footer .btt:hover {
  opacity: 0.8;
  ;
}

footer .btt img {
  max-height: 100%;
}









/* my css */


header {
  color: #545454;
}
header a {
  color: #545454;
}
header .socials img {
filter: brightness(0) saturate(100%) invert(60%) sepia(10%) saturate(400%) hue-rotate(60deg) brightness(95%) contrast(300%);
transition: filter 0.3s ease;

}
/* margla about  */
.about-section {
background-color: #94d3a2; /* Green background color */
padding: 50px 20px;
color: #ffffff; /* White text color */
}

.about-container {
max-width: 1200px;
margin: 0 auto;
}

.about-section h2 {
font-size: 30px;
font-weight: bold;
margin-bottom: 20px;
color: #ffffff;
}

.divider {
border: none;
border-top: 2px solid #ffffff;
width: 100%;
margin: 20px 0;
}

.about-content {
display: flex;
justify-content: space-between;
align-items: flex-start;
gap: 20px;
}


.about-text {
flex: 1;
font-size: 14px;
line-height: 1.3;
color: #ffffff;
padding-right: 20px;
text-align: justify;
}
.about-text-second {
  flex: 1;
  font-size: 14px;
  line-height: 1.3;
  color: #ffffff;
  padding-right: 20px;
  text-align: justify;
  }
  .about-text-third {
    flex: 1;
    font-size: 14px;
    line-height: 1.3;
    color: #ffffff;
    padding-top: 20px;
    text-align: justify;
    }

.about-video {
  width: 449px;
  height: 300px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000; /* Background color for letterboxing */
}

.about-video video {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain; /* Scale to fit without cropping */
}


.download-button {
display: inline-block;
padding: 12px 20px;
background-color: #ffffff;
color: #94d3a2;
font-size: 18px;
font-weight: bold;
text-align: center;
text-decoration: none;
border-radius: 5px;
transition: background-color 0.3s ease;
/* margin-top: 20px; */
}

.download-button:hover {
background-color: #e0e0e0;
}



/* Eligibility Criteria */

.criteria-section {
padding: 50px 20px;
color: #333; /* Text color */
}

.content-container {
  display: flex;

max-width: 1213px;
margin: 0 auto;
}

.content-container-button {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 45%;
}

.content-container-button .button {
  background-color: #d4f1ce; /* Light green for inactive buttons */
  color: #70bf6a; /* Text color inside buttons */
  padding: 10px;
  border-radius: 8px;
  text-align: left;
  transition: background-color 0.3s ease;
  cursor: pointer;
  position: relative; /* For positioning the arrow */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-container-button .button h2 {
  color: #ffffff;
  font-size: 1.4rem;
  margin: 0;
  font-weight: bold;
}

.content-container-button .button::after {
  content: '➔'; /* Unicode arrow character */
  color: #70bf6a; /* Arrow color */
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.content-container-button .button.active {
  background-color: #70bf6a; /* Darker green for the selected button */
  color: #ffffff;
}

.content-container-button .button.active::after {
  color: #ffffff; /* Arrow color for the selected button */
}

.content-container-button .button1 {
  display: inline-block;
  padding: 12px 20px;
  background-color: #ffffff;
  color: #94d3a2;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  }
  
  .content-container-button .button1:hover {
  background-color: #e0e0e0;
  }

.apply-button1 {
  display: inline-block;
  margin-top: 0px;
  padding: 12px 20px;
  background-color: #70bf6a; /* Green color */
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.apply-button1:hover {
  background-color: #5aa556; /* Slightly darker green on hover */
}


.content-container-data {
 
  background-color: #e8f0e8;
  width: 55%;
  padding: 20px;
}

.criteria-list {
  list-style: none;
  padding: 0;
  margin: 0;

}

.divider {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.apply-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #70bf6a;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.apply-button:hover {
  background-color: #5da857;
}

.content-container-data {
  width: 70%;

}

.criteria-section h2 {
font-size: 30px;
font-weight: bold;
color: #545454;
margin-bottom: 10px;
}

.divider {
border: none;
border-top: 2px solid #545454;
width: 100%;
margin-bottom: 20px;
}


.criteria-list {
  list-style-type: disc; /* Changes list style to bullet points */
  padding-left: 20px;
  margin-bottom: 30px;
  font-size: 14px;
  text-align: left;
}

.criteria-list li {
  margin-bottom: 1.5em; /* Adds spacing between different <li> elements */
  line-height: 1.2; /* Sets line height within the same <li> element */
}

.criteria-list li:not(:last-child) {
  margin-bottom: 1em; /* Ensures proper spacing between <li> elements */
}

.apply-button {
display: inline-block;
padding: 10px 20px;
background-color: #94d3a2; /* Green button background */
color: #ffffff;
font-size: 18px;
font-weight: bold;
text-align: center;
text-decoration: none;
border-radius: 5px;
transition: background-color 0.3s ease;
margin-top: 20px;
}

.apply-button:hover {
background-color: #6ea473;
}


.rf-about-section {
  padding: 50px 20px;
  color: #333;
  width: 25%; /* Keep the fixed width as you specified */
}

.rf-content-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  box-sizing: border-box;
}

.rf-text-content {
  background-color: #0088b9;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  width: 100%; /* Ensures content takes the full width of the container */
  max-width: 100%; /* Ensure it does not exceed the parent container */
  box-sizing: border-box;
  overflow-wrap: break-word; /* Prevents long words from overflowing */
  word-wrap: break-word;     /* For legacy support */
  white-space: normal;       /* Allows text to wrap */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: justify;
}

.rf-text-content h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff;
}

.rf-text-content p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 20px;
}


.rf-twitter-content {
background-color: #ffffff; /* White background */
padding: 20px;
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
width: 30%; /* Adjust as needed */
}

.rf-twitter-content .rf-twitter-timeline {
display: block;
width: 100%;
height: 400px; /* Adjust height as needed */
}

.faq-section {
background-color: #f4f8f4; /* Light green background */
padding: 50px 20px;
color: #333; /* Text color */
}

.faq-container {
max-width: 1200px;
margin: 0 auto;
font-family: Arial, sans-serif;
padding-top: 85px;
}

.faq-section h2 {
font-size: 32px;
font-weight: bold;
color: #545454;
margin-bottom: 20px;
}

.divider {
border: none;
border-top: 2px solid #f7f2f2;
width: 100%;
margin-bottom: 20px;
}

.faq-item {
border: 1px solid #6ea473; /* Green border */
margin-bottom: 10px;
border-radius: 5px;
overflow: hidden;
}

.faq-item label {
display: block;
padding: 15px;
font-size: 18px;
font-weight: bold;
cursor: pointer;
position: relative;
}

.faq-item label::after {
content: '+';
font-size: 24px;
position: absolute;
right: 20px;
top: 50%;
transform: translateY(-50%);
color: #6ea473; /* Green color */
transition: transform 0.3s ease;
}

.faq-item input[type="checkbox"] {
display: none;
}

.faq-item input[type="checkbox"]:checked + label::after {
content: '-';
transform: translateY(-50%) rotate(180deg);
}

.faq-content {
display: none;
padding: 0 15px 15px;
font-size: 16px;
}

.faq-item input[type="checkbox"]:checked ~ .faq-content {
display: block;
}

.faq-note {
margin-top: 20px;
padding: 15px;
border: 1px solid #6ea473;
border-radius: 5px;
font-size: 16px;
line-height: 1.6;
background-color: #ffffff;
}
/* footer */
footer {
background-color: #94d3a2; /* Green background color */
padding: 20px 0;
color: #ffffff; /* White text color */
}

footer .content {
margin: 0 auto;
}

footer a {
color: #ffffff; /* White text color for links */
text-decoration: none;
}

footer a:hover {
color: #e0e0e0; /* Slightly lighter color on hover */
}

footer .title {
font-weight: bold;
margin-bottom: 10px;
font-size: 18px;
}

footer .map iframe {
border: none;
}

footer .btt img {
width: 42px; /* Adjust as needed */
}

footer .col {
margin-bottom: 20px;
}

footer .subscribe .title {
margin-bottom: 15px;
}

footer .subscribe input[type="text"] {
width: 100%;
padding: 10px;
margin-bottom: 10px;
border-radius: 5px;
border: 1px solid #cccccc;
}

footer .subscribe input[type="submit"] {
background-color: #4a90e2; /* Button color */
color: #ffffff;
padding: 10px 20px;
border: none;
border-radius: 5px;
cursor: pointer;
}

footer .subscribe input[type="submit"]:hover {
background-color: #357ab7; /* Darker button color on hover */
}

footer .social-icons img {
width: 32px;
height: 32px;
margin-right: 10px;
}



/* Quicklinks */
.quicklinks-feed {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px 0;
}

.quicklinks {
  /* background-color: #cce7f1; */
  border-radius: 20px;
  padding: 50px;
  max-width: fit-content;
  margin-left: 15px;
  margin-right: 15px;
  width: calc(100% - 30px);
}

.quicklinks .title-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quicklinks .title-nav .title {
  font-size: 36px;
  font-weight: var(--bold);
  color: var(--blue);
}

.quicklinks .title-nav .nav {
  display: flex;
  gap: 0 5px;
}

.quicklinks .title-nav .nav .prev,
.quicklinks .title-nav .nav .next {
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
}

.quicklinks .title-nav .nav .prev img,
.quicklinks .title-nav .nav .next img {
  height: 100%;
}

.quicklinks .title-nav .nav .prev:hover,
.quicklinks .title-nav .nav .next:hover {
  opacity: 0.8;
}

.quicklinks .title-nav .nav .slick-disabled {
  opacity: 0.2;
  pointer-events: none;
}

.quicklinks .title-nav .nav .next {
  transform: rotate(180deg);
}

.quicklinks .carousel {
  border-top: 1px solid var(--blue);
  padding-top: 20px;
  margin-top: 15px;
}

.quicklinks .carousel .item {
  margin: 0 10px;
  text-decoration: none;
}

.quicklinks .carousel .slick-list {
  margin-left: -10px;
  margin-right: -10px;
}

.quicklinks .carousel .item .thumb img {
  border-radius: 5px;
  object-fit: cover;
    object-position: top center;
}

.quicklinks .carousel .item .title {
  margin-top: 8px;
  font-weight: var(--medium);
  color: #333;
  line-height: 12px;
 
}

.quicklinks .carousel .item .date {
  margin-top: 12px;
  color: #333;
}

.quicklinks-feed .quicklinks {
  margin-left: unset;
  margin-right: unset;
  width: 100%;
}

.quicklinks.team {
  padding: 15px 15px;
  max-width: unset;
  margin-top: 30px;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.quicklinks.team .nav {
  margin-left: auto;
}

.quicklinks.team .item {
  position: relative;
}

.quicklinks.team .item .team-tab {
  position: absolute;
  background-color: #FAFFF7;
  border-radius: 5px 0 0 5px;
  bottom: 20px;
  right: 0;
  width: 90%;
  font-size: 20px;
  padding: 10px;
  box-shadow: -5px 5px 0 0 #8be1cf, -5px 5px 0 0 #8be1cf;
  font-weight: var(--bold);
}

.quicklinks .carousel .item .thumb {
  height: 265px;
  display: -webkit-inline-box;
}

.quicklinks.team .item .team-tab .name {}

.quicklinks.team .item .team-tab .position {}

.quicklinks .carousel {
  padding-top: 20px;
  margin-top: 15px;
}

/* Candidate image and hover effect */
.thumb {
  position: relative;
  overflow: hidden;
}

.candidate-image {
  display: block;
  width: 100%;
  height: auto;
  transition: opacity 0.3s ease;
}

.thumb:hover .candidate-image {
  opacity: 0.7; /* Fade effect */
}

/* Overlay for LinkedIn icon */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.thumb:hover .overlay {
  opacity: 1;
}

.linkedin-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkedin-icon img {
  width: 100%;
  height: auto;
}


.gallery-container {
  padding: 0px;
  padding-bottom: 50px !important;
}

.gallery-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--blue);
}

/* Gallery grid layout */
.gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 15px; /* Space between images */
}

.gallery-item img {   
  width: 100%;   
  height: 150px; /* Adjust height to make horizontal images fit nicely */   
  object-fit: cover; /* Ensures the image covers the area without stretching */   
  object-position: top; /* Anchors the image to the top, so it trims from the bottom */ 
  cursor: pointer;   
  border-radius: 8px;   
  transition: transform 0.3s ease;   
  border: 1px solid var(--blue); 
}

.gallery-item img:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Lightbox styling */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(148, 211, 162, 0.8); /* Light green with 80% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.lightbox-image {
  max-width: 90%;
  max-height: 80%;
  border-radius: 8px;
}

.lightbox-close,
.lightbox-prev,
.lightbox-next {
  position: absolute;
  background: transparent;
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.lightbox-close {
  top: 20px;
  right: 20px;
}

.lightbox-prev {
  left: 20px;
}

.lightbox-next {
  right: 20px;
}

/* organizing committee */

.organizing-wrapper {
  max-width: 1385px;
  margin: 0 auto;
  padding: 20px;
  width: 75%;
}

.organizing-title-nav {
  text-align: left;
  margin-bottom: 20px;
}

.organizing-title {
  font-size: 30px;
  font-weight: bold;
  color: var(--blue);
}

.organizing-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to the start */
  margin-top: 50px;
}

.organizing-item {
  text-align: center;
  width: 250px;
  padding: 10px;
  position: relative;
  margin-right: 30px; /* Adds gap only to the right of each item */

}

.organizing-item:last-child {
  margin-right: 0; /* Removes right margin from the last item in the row */
}

.organizing-thumb {
  position: relative;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 8px;
  transition: transform 0.3s ease;
  border: 1px solid var(--blue);
}

.organizing-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.organizing-thumb:hover .organizing-image {
  opacity: 0.5; /* Fades the image */
}

.organizing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(112, 191, 106, 0.7); /* Light green overlay color */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  z-index: 10;
}

.organizing-thumb:hover .organizing-overlay {
  opacity: 1; /* Show overlay with light green background on hover */
}

.organizing-linkedin-link img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
}

.organizing-linkedin-link:hover img {
  transform: scale(1.2);
}

.organizing-info {
  margin-top: 10px;
  text-align: left;
}

.organizing-name {
  color: #545454;
  font-weight: 900;
  font-size: 1.1rem;
}

.organizing-designation,
.organizing-organization {
  font-size: 0.9rem;
  color: #545454;
}

.organizing-divider {
  width: 99%;
  height: 2px;
  background-color: #70bf6a;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
}
.gallery-divider {
  width: 99%;
  height: 2px;
  background-color: #70bf6a;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
}
/* organizing committee another div  */
.organization-content-container {
  display: flex;
}
/* fellow css  */
.fellow-divider {
  width: 100%;
  height: 2px;
  background-color: #70bf6a;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
}
.cohort-gallery {
  /* text-align: center; */
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.cohort-gallery-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--blue);
}

/* Grid layout for gallery */
.cohort-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive 4 columns */
  gap: 15px;
}

.cohort-gallery-item {
  position: relative;
  text-align: center;
}

.cohort-gallery-thumb {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid var(--blue);
  transition: transform 0.3s ease;
}

.cohort-gallery-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.cohort-gallery-thumb:hover .cohort-gallery-image {
  transform: scale(1.05);
  opacity: 0.7; /* Light green effect on hover */
}

.cohort-gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 128, 0, 0.3); /* Light green overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.cohort-gallery-thumb:hover .cohort-gallery-overlay {
  opacity: 1;
}

.cohort-gallery-linkedin-icon img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
}


.cohort-gallery-linkedin-icon:hover img {
  transform: scale(1.2);
}

.cohort-gallery-info {
  margin-top: 10px;
  text-align: left;
}

.cohort-gallery-name {
  font-weight: 900;
  font-size: 1.1rem;
  color: #545454;
}

/* experts css  */
.experts-container {
  /* text-align: center; */
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.experts-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--blue);
}

/* Grid layout for experts */
.experts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive 4 columns */
  gap: 20px;
}

.experts-item {
  position: relative;
  text-align: center;
}

.experts-thumb {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid var(--blue);
  transition: transform 0.3s ease;
}

.experts-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.experts-thumb:hover .experts-image {
  transform: scale(1.05);
  opacity: 0.7; /* Light green effect on hover */
}

.experts-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 128, 0, 0.3); /* Light green overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 8px;
}

.experts-thumb:hover .experts-overlay {
  opacity: 1;
}

.experts-linkedin-icon img {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease;
}

.experts-linkedin-icon:hover img {
  transform: scale(1.2);
}

.experts-info {
  margin-top: 10px;
  text-align: left; /* Aligns text to the left under image */
}

.experts-name {
  font-weight: 900;
  font-size: 1.1rem;
  color: #545454;
}

.experts-organization,
.experts-designation {
  font-size: 0.9rem;
  color: #545454;
}


.experts-divider {
  width: 100%;
  height: 2px;
  background-color: #70bf6a;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
}
/* ribbon  */
.full-width-green {
  width: 100%;
  background-color: var(--blue);
  color: #ffffff; /* White text color */
  text-align: center; /* Center align text */
  padding: 8px 0; /* Add some padding for spacing */
  box-sizing: border-box; /* Ensure full-width without overflow */
  font-size: 20px;
  font-weight: 900;
}
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
}

.pagination li {
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.pagination li.active {
  background-color: #70bf6a;
  color: white;
}

.pagination li:hover {
  background-color: #ddd;
}

.pagination li.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: #94d3a2;
  padding: 30px;
  border-radius: 20px;
  max-width: 600px;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.popup-close {
  background: none;
  border: none;
  font-size: 18px;
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 10;
}

.popup-title {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 15px;
}

.popup-message {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 20px;
  line-height: 1.5;
}

.highlight {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff; /* Golden color for emphasis */
}

.popup-button {
  display: inline-block;
  padding: 12px 20px;
  background: #ffffff;
  color: #5ba377;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.popup-button:hover {
  background: #ffcc00;
  color: #ffffff;
}
img.rf-logo {
  max-width: 140px;
  float: right;
}
